<template>
    <div class="centerPanel">
        <ul class="mapNav">
            <li
                v-for="(item,index) in navList"
                :key="item.code"
                :class="{active:navIndex==index}"
                @click="changeNav(index)"
            ></li>
        </ul>
        <component ref="refComponent" :is="navList[navIndex].component"></component>
    </div>
</template>

<script>
import productionMap from './components/productionMap/productionMap.vue';
import supplyMap from './components/supplyMap/supplyMap.vue';
export default {
    name: 'center-pannel',
    components: {
        productionMap,
    },
    data() {
        return {
            loading: false,
            time: '',
            stationId: '',
            stationData: [],
            allStationData: [],
            // mapNav 选择nav 类型；production->生产物流，supply->供应物流
            navIndex: 0,
            navList: [{
                title: '生产物流',
                code: 'production',
                component: productionMap,
            },
            {
                title: '供应物流',
                code: 'supply',
                component: supplyMap,
            },
            ],
        };
    },
    methods: {
        // 初始化页面
        initPage() {
            this.time = this.$parent.time;
            this.tenantId = this.$parent.tenantId;
            this.stationData = this.$parent.stationData;
            this.allStationData = this.$parent.allStationData;
            this.loadPage();
        },
        // 加载数据
        loadPage() {
            this.$refs.refComponent.initPage();
        },
        // 更新地图数据
        updateMapData() {
            this.$refs.refComponent.updateMapData();
        },
        // 生产、供应切换地图
        changeNav(index) {
            this.navIndex = index;
            this.$nextTick(() => {
                this.loadPage();
            });
        },
        resize() {
            this.$refs.refComponent.resize();
        },
    },
};
</script>

<style lang="stylus" scoped>
.centerPanel
    width 100%;
    height 100%;
    .mapNav
        display flex
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        z-index: 99;
        li
            width 1.29rem
            height 0.48rem
            cursor pointer
            margin-right 0.1rem

            &:nth-of-type(1)
                background url('./images/btn1_normal.png')
                background-size 100% 100%

                &.active, &:hover
                    background url('./images/btn1_highlight.png')

            &:nth-of-type(2)
                background url('./images/btn2_normal.png')
                background-size 100% 100%

                &.active, &:hover
                    background url('./images/btn2_highlight.png')

            &:nth-of-type(3)
                background url('./images/btn3_normal.png')
                background-size 100% 100%

                &.active, &:hover
                    background url('./images/btn3_highlight.png')
</style>
